@font-face {
  font-family: "SFProDisplay";
  src: url("../fonts/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("../fonts/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("../fonts/SFProDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("../fonts/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("../fonts/SFProDisplay-Heavy.ttf") format("truetype");
  font-weight: 800;
}
html {
  font-size: 62.5%;
}

body {
  font-family: "SFProDisplay", sans-serif;
}

body#tinymce, .wp-content {
  position: relative;
}
body#tinymce *, .wp-content * {
  margin: 0;
  padding: 0;
}
body#tinymce h2, .wp-content h2 {
  font-size: 5rem;
  line-height: 1.2;
  font-weight: 800;
}
@media screen and (max-width: 991px) {
  body#tinymce h2, .wp-content h2 {
    font-size: 2.4rem;
  }
}
body#tinymce h3, .wp-content h3 {
  font-size: 3.8rem;
  line-height: 1.5;
  font-weight: 800;
}
@media screen and (max-width: 991px) {
  body#tinymce h3, .wp-content h3 {
    font-size: 2.2rem;
  }
}
body#tinymce h4, .wp-content h4 {
  font-size: 2.6rem;
  line-height: 1.5;
  font-weight: 800;
}
@media screen and (max-width: 991px) {
  body#tinymce h4, .wp-content h4 {
    font-size: 1.8rem;
  }
}
body#tinymce h5, .wp-content h5 {
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: 800;
}
@media screen and (max-width: 991px) {
  body#tinymce h5, .wp-content h5 {
    font-size: 1.6rem;
  }
}
body#tinymce h6, .wp-content h6 {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 800;
}
@media screen and (max-width: 991px) {
  body#tinymce h6, .wp-content h6 {
    font-size: 1.6rem;
  }
}
body#tinymce p, .wp-content p {
  margin-top: 20px;
  font-size: 1.8rem;
  line-height: 3.2rem;
}
@media screen and (max-width: 991px) {
  body#tinymce p, .wp-content p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
body#tinymce ul, .wp-content ul {
  margin-top: 20px;
  list-style: disc;
  padding-inline-start: 20px;
  padding-left: 20px;
}
body#tinymce ol, .wp-content ol {
  margin-top: 20px;
  list-style: decimal;
  padding-inline-start: 20px;
  padding-left: 20px;
}
body#tinymce li, .wp-content li {
  font-size: 1.8rem;
  line-height: 3.2rem;
  list-style: inherit;
}
@media screen and (max-width: 991px) {
  body#tinymce li, .wp-content li {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
body#tinymce li + body#tinymce li, body#tinymce li + .wp-content li, .wp-content li + body#tinymce li, .wp-content li + .wp-content li {
  margin-top: 15px;
}
body#tinymce img, .wp-content img {
  max-width: 100%;
}
body#tinymce img.aligncenter, .wp-content img.aligncenter {
  margin-top: 10px;
  margin-bottom: 10px;
}