@use 'sass:map';

//-------------------------------------------
// Fonts
//-------------------------------------------


//-------------------------------------------
// Breakpoint
//-------------------------------------------
$breakpoints: (
	"sm" : 640px,
	"md" : 767px,
	"lg" : 1024px,
	"xl" : 1280px,
	"xxl" : 1440px,
	"max" : 1920px,
) !default;

@mixin max-w($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media screen and (max-width: #{map-get($breakpoints, $breakpoint)}) {
			@content;
		}
	} @else {
		@media screen and (max-width: #{$breakpoint}) {
			@content;
		}
	}
}

@mixin min-w($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media screen and (min-width: #{map-get($breakpoints, $breakpoint) + 1px}) {
			@content;
		}
	} @else {
		@media screen and (min-width: #{$breakpoint}) {
			@content;
		}
	}
}

@mixin pc {
	@include min-w(992px) {
		@content;
	}
}

@mixin sp {
	@include max-w(991px) {
		@content;
	}
}

//-------------------------------------------
// Other
//-------------------------------------------
@mixin ios {
	@supports (-webkit-touch-callout: none) {
		@content;
	}
}

@mixin shadow {
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2)
}

@mixin hover {
	@include pc {
		&:hover {
			@content;
		}
	}
}

@mixin clear {
	*zoom: 1;
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

@mixin mask {
	position: relative;
	z-index: 1;

	&::before {
		position: absolute;
		content: '';
		width: 100%;
		height: calc(100% - 62px);
		top: 62px;
		left: 0;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
		opacity: 0.7;
		z-index: 0;
	}
}
