@font-face {
	font-family: "SFProDisplay";
	src: url("../fonts/SFProDisplay-Regular.ttf") format("truetype");
	font-weight: 400
}

@font-face {
	font-family: "SFProDisplay";
	src: url("../fonts/SFProDisplay-Medium.ttf") format("truetype");
	font-weight: 500
}

@font-face {
	font-family: "SFProDisplay";
	src: url("../fonts/SFProDisplay-Semibold.ttf") format("truetype");
	font-weight: 600
}

@font-face {
	font-family: "SFProDisplay";
	src: url("../fonts/SFProDisplay-Bold.ttf") format("truetype");
	font-weight: bold
}

@font-face {
	font-family: "SFProDisplay";
	src: url("../fonts/SFProDisplay-Heavy.ttf") format("truetype");
	font-weight: 800
}
