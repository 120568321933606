@charset "UTF-8";
@use './settings/mixin' as m;
@forward './foundation/fonts';

html {
  font-size: 62.5%;
}

body {
  font-family: 'SFProDisplay', sans-serif;
}

body#tinymce, .wp-content {
  position: relative;

  * {
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 5rem;
    line-height: 1.2;
    font-weight: 800;

	@include m.sp{
		font-size: 2.4rem;
	}
  }

  h3 {
    font-size: 3.8rem;
    line-height: 1.5;
    font-weight: 800;

	@include m.sp{
		font-size: 2.2rem;
	}
  }

  h4 {
    font-size: 2.6rem;
    line-height: 1.5;
    font-weight: 800;

	@include m.sp{
		font-size: 1.8rem;
	}
  }

  h5 {
    font-size: 2.4rem;
    line-height: 1.5;
    font-weight: 800;

	@include m.sp{
		font-size: 1.6rem;
	}
  }

  h6 {
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 800;

	@include m.sp{
		font-size: 1.6rem;
	}
  }

  p {
    margin-top: 20px;
    font-size: 1.8rem;
    line-height: 3.2rem;

	@include m.sp{
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
  }

  ul {
    margin-top: 20px;
    list-style: disc;
    padding-inline-start: 20px;
    padding-left: 20px;
  }

  ol {
    margin-top: 20px;
    list-style: decimal;
    padding-inline-start: 20px;
    padding-left: 20px;
  }

  li {
    font-size: 1.8rem;
    line-height: 3.2rem;
    list-style: inherit;

	@include m.sp{
		font-size: 1.6rem;
		line-height: 2.4rem;
	}

    & + & {
      margin-top: 15px;
    }
  }

  img {
    max-width: 100%;

    &.aligncenter {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

}
